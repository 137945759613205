.form-control {
  &.underlined {
    padding: 0.375rem 0.75rem;
  }
}

.not-visible {
  visibility: hidden;
}

.hidden {
  display: none;
}

.typeahead {
  z-index: 1051;
}

.full-width {
  width: 99.99%;
}

.profile-row {
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;

  .card {
    height: calc(100% - 20px);

    .notice{
      font-size: 0.8em;
      margin: 0.5em 0;
      sup{
        padding-right: 0.5em;
      }
    }
    .main-address{
      .form-check {
        margin-top: 0.2rem;
        padding-left: 0.10rem;
        input + span {
          font-size: 0;
          -webkit-text-size-adjust: none;
          white-space: nowrap;
        }

        input:checked + span {
          font-size: 0.8em;
        }
      }
    }
    .btn-group-sm{
      text-align: right;
      a{
        margin-top: 0.2rem;
      }
    }
  }
}

#emails-list {
  &.single {
    .delete-email {
      pointer-events: none;
      visibility: hidden;
    }
  }
}

#btn-buy-voucher sup{
  color: #333333;
}

.form-check-inline span a {
  vertical-align: baseline;
}

label.required {
  &:after {
    content: ' *';
    font-size: 0.8em;
    position: relative;
    top: -5px;
  }

  &.custom-control-label,
  &.form-check-label {
    &:after {
      display: none;
    }
  }
}

input[type="radio"].radio,
input[type="checkbox"].checkbox {
  display: block;
  left: 3px;
  position: absolute !important;
  top: 8px;
  z-index: -1;
}

.checkbox-inline {
  .has-error {
    color: red;
    font-weight: 400;

    &:before {
      content: '';
      display: block;
    }
  }
}

.form-check {
  .has-error {
    color: red;
  }
}

figure {
  border-radius: 5px;
  display: inline-block;
  overflow: hidden;
  position: relative;

  figcaption {
    background: rgba(240, 243, 246, 0.85);
    color: #4f5f6f;
    font-weight: bold;
    left: 0;
    margin-top: -2.4em;
    padding: 0.5em;
    position: absolute;
    top: 100%;
    text-align: center;
    text-transform: none;
    width: 100%;
  }

  &:hover {
    -webkit-box-shadow: 0 0 30px -10px #4f5f6f;
    -moz-box-shadow: 0 0 30px -10px #4f5f6f;
    box-shadow: 0 0 30px -10px #4f5f6f;
  }
}

table {
  tbody {
    td {
      &.no-wrap {
        white-space: nowrap;
      }

      .indented {
        padding-left: 30px;
      }
    }
  }

  &.table-no-wrap {
    th,
    td {
      white-space: nowrap;
    }
  }
}

.sidebar{
  .bottom-menu{
    bottom: 15px;
    left: 0;
    margin: 0;
    padding-left: 20px;
    position: absolute;
    width: 100%;
    li{
      list-style: none;
      a{
        font-size: 0.8em;
        text-decoration: none;
      }
    }
  }
}

.auth {
  text-align: center;

  .auth-container {
    text-align: left;

    .card {
      .auth-logo {
        left: 0;
        position: absolute;
        top: -75px;
        z-index: 1;
      }

      .auth-header {
        padding-top: 40px;
      }

      .auth-content {
        padding: 30px;
      }

      h3, h4, h5 {
        margin-bottom: 20px;
      }

      .actions {
        margin-top: 30px;

        &.as-rows {
          margin-top: 0;

          button {
            display: block;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .auth {
    .auth-container {
      padding: 100px 25px 0 25px;
    }

    &.registration {
      .auth-container {
        width: 100%;
      }
    }
  }
}

@media (max-width: 599.98px) {
  .auth {
    .top-gap {
      font-size: 0.8em;
      padding-left: 5px;
      margin-top: 5px;

      .label {
        display: none;
      }
    }
  }
  .sidebar-header {
    .brand {
      img {
        height: auto;
        max-width: 100px;
      }
    }
  }
  .profile-row {
    .card {
      .col-6{
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .main-address{
        -ms-flex: 0 0 60%;
        flex: 0 0 60%;
        max-width: 60%;
      }
      .btn-group-sm{
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%;
        text-align: right;
      }
    }
  }
}
