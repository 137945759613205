@import "../theme/_variables";
@import "skins/flex";

.auth {
  &.registration {
    .auth-container {
      max-width: 768px;
      width: 100%;
      @media (min-width: 767.98px) {
        top: 90px;
        -webkit-transform: translateY(0) translateX(-50%);
        transform: translateY(0) translateX(-50%);
      }

      .card {
        .auth-content {
          .registration-form {
            .actions {
              > div {
                min-height: 40px;
                position: relative;
                text-align: center;

                .btn {
                  cursor: pointer;
                }

                .btn-save,
                .btn-next {
                  position: absolute;
                  right: 0;
                }

                .btn-back,
                .btn-previous {
                  left: 0;
                  position: absolute;
                }
              }
            }


            .organization {
              display: none;
            }

            &.user-data {
              .licence-select {
                display: none;
              }
            }

            &.organization {
              .organization {
                display: block;
              }

              .normal {
                display: none;
              }
            }

            &.licence-select {
              .user-data {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.accept-newsletter {
  font-size: 0.8em;

  .form-check {
    padding-left: 0;

    span {
      white-space: nowrap;
    }
  }
}

.licence-type-selection {
  .licence-type {
    margin-bottom: 10px;
  }

  .error-no-selection{
    display: none;
    position: relative;
    width: 100%;
    > span {
      @include flexbox;
      @include justify-content(center);
      position: absolute;
      top: 0;
      width: 100%;
      > span {
        background: #ffffff;
        border: 1px solid #d1ccc9;
        border-radius: 10px;
        -webkit-box-shadow: 0 0 20px -10px #4f5f6f;
        -moz-box-shadow: 0 0 20px -10px #4f5f6f;
        box-shadow: 0 0 20px -10px #4f5f6f;
        line-height: 2em;
        max-width: 95%;
        padding: 5px 10px;
        position: relative;


        &:before {
          background: #dd3030;
          border-radius: 2px;
          color: #ffffff;
          content: "\0021";
          margin-right: 10px;
          padding: 3px 10px;
        }

        &:after {
          background: url(../images/arrow-top.png) no-repeat;
          background-size: 100% auto;
          bottom: 100%;
          content: '';
          height: 20px;
          left: 50%;
          margin-left: -10px;
          position: absolute;
          width: 20px;
        }
      }
    }
  }

  .reduction-descriptions {
    width: 100%;
    .reduction-description {
      background: #76D4F5;
      color: #ffffff;
      display: none;
      font-weight: bold;
      margin-bottom: 10px;
      padding: 20px;
    }
  }

  .quantity {
    display: none;
    margin-top: 40px;
  }
}
.organization {
  .licence-type-selection {
    .quantity {
      display: block;
    }
    @for $i from 0 through 10 {
      &[data-selected="#{$i}"] {
        .reduction-descriptions{
          .reduction-description {
            &:nth-of-type(#{$i + 1}) {
              display: block;
            }
          }
        }
      }
    }
  }
}