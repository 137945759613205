/*************************************************************
*						App Variables
**************************************************************/


/*************************************************************
*					  Colors Definitions
**************************************************************/

$color-primary: #85CE36 !default;
$color-primary-light: lighten($color-primary, 10%) !default;
$color-primary-lighter: lighten($color-primary-light, 6%) !default;
$color-primary-dark: darken($color-primary, 10%) !default;
$color-primary-darker: darken($color-primary-dark, 6%) !default;

$color-divider: #d7dde4 !default;
$color-bg: #f0f3f6 !default;

$color-text: #4f5f6f !default;
$color-text-light: #7e8e9f !default;
$color-text-muted: #C2CCD6;
$color-text-inverse: #ffffff !default;
$color-text-passive: #c5c5c5 !default;

$color-success: #4bcf99 !default;
$color-info: #76D4F5 !default;
$color-warning: #fe974b !default;
$color-danger: #FF4444 !default;
$color-inverse: #131e26 !default;


/*************************************************************
*					Common Element Variables
**************************************************************/

// Buttons
$btn-radius: 25px !default;

$btn-primary-color: $color-primary !default;
$btn-primary-color-text: $color-text-inverse !default;


// Links
$link-color: #969696 !default;
$link-transition: initial;
$link-decoration: underline;

$link-hover-color: darken($color-primary, 8%) !default;
$link-hover-decoration: underline !default;

// Header
$header-height: 70px !default;
$header-height-xs: 50px !default;

// Sidebar
$sidebar-width: 240px !default;

// Footer
$footer-height: 50px !default;

$sidebar-color-primary: darken(#4f5f6f, 10%) !default;
$sidebar-color-secondary: darken($sidebar-color-primary, 6%) !default;

$sidebar-color-text: fade-out($color-text-inverse, 0.5) !default;
$sidebar-color-text-active: $color-text-inverse;

// Dropdowns
$dropbown-color-border: fade-out($color-text-light, 0.9) !default;
$dropdown-link-hover-color-bg: #f5f5f5 !default;

// Content
$content-padding-x-xl: 40px !default;
$content-padding-y-xl: 35px !default;

$content-padding-x-lg: 35px !default;
$content-padding-y-lg: 30px !default;

$content-padding-x-md: 20px !default;
$content-padding-y-md: 25px !default;

$content-padding-x-sm: 20px !default;
$content-padding-y-sm: 20px !default;

$content-padding-x-xs: 10px !default;
$content-padding-y-xs: 15px !default;


// Card
$card-padding-x: 15px !default;
$card-padding-x-xl: 20px !default;
$card-padding-x-sm: 10px !default;

$card-margin-bottom: 10px;

// Item list
$item-list-color-border: lighten($color-divider, 6%) !default;

// Charts
$chart-color-primary: $color-primary !default;
$chart-color-secondary: lighten($chart-color-primary, 10%) !default;



/* ***************************************************
*			Page Specific Element Variables
****************************************************** */

// Dashboard
$dashboard-stat-icon-color: #BDBDBD !default;
