@import 'flex';

@font-face {
  font-family: 'BicepsAdminRegular';
  src: url('fonts/BicepsAdminRegular.eot');
  src: url('fonts/BicepsAdminRegular.eot?#iefix') format('embedded-opentype'),
  url('fonts/BicepsAdminRegular.svg#BicepsAdminRegular') format('svg'),
  url('fonts/BicepsAdminRegular.ttf') format('truetype'),
  url('fonts/BicepsAdminRegular.woff') format('woff'),
  url('fonts/BicepsAdminRegular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('fonts/Montserrat-Regular.eot');
  src: url('fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg'),
  url('fonts/Montserrat-Regular.ttf') format('truetype'),
  url('fonts/Montserrat-Regular.woff') format('woff'),
  url('fonts/Montserrat-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('fonts/Montserrat-Italic.eot');
  src: url('fonts/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Montserrat-Italic.svg#Montserrat-Italic') format('svg'),
  url('fonts/Montserrat-Italic.ttf') format('truetype'),
  url('fonts/Montserrat-Italic.woff') format('woff'),
  url('fonts/Montserrat-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

html,
body {
  font-family: 'Montserrat', sans-serif;
  color: #554848;
}

h2 {
  &.welcome {
    font-weight: 700;
  }
}

.footer{
  a{
    color: #554848;
    text-decoration: none;
    text-transform: uppercase;
  }
  > .cga{
    > a{
      padding: 0 30px;
      > span{
        &:nth-child(2){
          display:none;
        }
      }
    }
  }
}
@media (max-width: 1279.99px) {
  h2 {
    &.welcome {
      font-size: 1.2em;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      padding-top: 20px !important;
      text-align: center;
    }
  }
}

@media (max-width: 999.98px) {
  h2 {
    &.welcome {
      font-size: 1.2em;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      padding-top: 20px !important;
      text-align: center;
    }
  }
}

a:not(.btn):hover {
  color: #554848;
}

.m-right-5 {
  margin-right: 5px;
}

form[name="select_invoice_sending_method_form"]{
  margin-bottom: 30px;
  > .card-header{
    > .header-block{
      width: 100%;
    }
  }
  .btn-primary{
    width: 260px;

    @media (max-width: 430px) {
      width: auto;
    }
  }
}

form[name="enter_voucher_number_form"]{
  > .card-header{
    > .header-block{
      width: 100%;
    }
  }
}

form[name="change_password_form"]{
  > .card-footer{
    border-top: none;
  }
}

.bookmarks {
  .bookmark {
    > a {
      display: block;
      margin: auto;
      overflow: hidden;
      width: 50%;

      figure {
        margin-bottom: 30px;
        overflow: visible;

        img {
          height: auto;
          width: 200%;
        }

        figcaption {
          background: none;
          margin-top: 0;
          white-space: nowrap;
          width: 100%;
        }

        &:hover {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;

          img {
            margin-left: -100%;
          }
        }
      }
    }
    @media (max-width: 599.98px) {
      > a{
        width: 80%;
      }
    }
  }
}

.header {
  .header-block-nav {
    > ul {
      > li {
        > a {
          color: #ffffff;

          &:hover {
            color: #ffffff;
          }
        }
      }
    }

    .notifications {
      a {
        i {
          font-size: 1.4em;
        }
      }

      .counter {
        color: #ffffff !important;
      }
    }
  }
}

.dropdown-menu {
  .dropdown-item {
    color: #554848;

    i {
      color: #554848 !important;
    }

    &:hover {
      color: #554848 !important;
      text-shadow: 0 0 1px #554848;
    }
  }
}

h3, .h3 {
  font-size: 1.5rem;
}

.radio + span:before,
.checkbox + span:before {
  color: #D0CBCB;
  font-size: 26px;
}

.radio:checked + span:before,
.checkbox:checked + span:before {
  color: #554848;
}


.profile-row {
  .add-email-col {
    border-bottom: 1px solid #D0CBCB;
    margin: 0 0 1em 1em;
    max-width: calc(100% - 2em);
    padding-bottom: 1em;
    text-align: right;

    .btn {
      margin: 0;
    }
  }

  .accept-newsletter {
    .description {
      margin-left: 33px;
    }
  }

  .card .notice {
    margin-left: 33px;
  }

  #emails-list{
    .btn-group-sm{
      > a{
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  @media (max-width: 767.98px) {
    margin-left: -30px;
    margin-right: -30px;
  }
}

.auth-content {
  .btn-primary {
    margin: 0;
  }
}

.auth.registration{
  .actions{
    > div{
      .btn{
        margin: 0;
      }
      .btn-back{
        left: -20px !important;
      }
      .btn-previous{
        left: -20px !important;
      }
      .btn-save{
        right: -20px !important;
      }
      .btn-next{
        right: -20px !important;
      }
    }
  }
}

.notification-buttons{
  @include flexbox;
  @include justify-content(space-between);
  @include flex-wrap(wrap);
  > .btn{
    margin: 0;
  }
  @media (max-width: 599px) {
    @include flex-direction(column);
  }
  @media (min-width: 600px) {
    > .btn {
      font-size: 0.78em;
    }
  }
  @media (min-width: 765px) {
    > .btn {
      font-size: 1em;
    }
  }
}

.select-payment-method{
  [name="select_payment_method_form"]{
    .buttons {
      @include flexbox;
      @include justify-content(space-between);
      @include flex-wrap(wrap);
      button {
        margin: 0.5em 0 0 0;
      }
      .form-check-inline{
        margin-top: 0.5em;
        width: 100%;
      }
      @media (min-width: 1280px) {
        @include justify-content(center);
        button {
          margin: 0.5em 20px 0 20px;
        }
      }
      @media (max-width: 599px) {
        @include flex-direction(column);
        .form-check-inline{
          margin-top: -0.5em;
        }
      }
      @media (min-width: 600px) {
        button {
          font-size: 0.7em;
        }
      }
      @media (min-width: 765px) {
        button {
          font-size: 1em;
        }
      }
    }
  }
}
.select-invoice-sending-method{
  button{
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }
}
.pay-with-voucher{
  button{
    margin-bottom: 1em;
  }
}

.btn {
  background-color: #D0CBCB;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  margin: 0 20px;
  padding: 10px 30px;

  .fa {
    font-size: 1.3em;
    position: relative;
    top: 0.1em;
  }

  &:focus-visible {
    outline: none;
  }

  &.btn-primary {
    background-color: #D0CBCB;
    color: #4f5f6f;

    &:hover {
      background-color: #4f5f6f;
      color: #ffffff;
    }

    &:focus {
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
    }
  }
}

.btn.btn-primary:not(:disabled):not(.disabled):active,
.btn.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-primary.dropdown-toggle {
  background-color: #4f5f6f;
  color: #ffffff;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.card {
  border-radius: 15px;
  -webkit-box-shadow: 0 0 30px -10px #4f5f6f;
  -moz-box-shadow: 0 0 30px -10px #4f5f6f;
  box-shadow: 0 0 30px -10px #4f5f6f;

  &.card-block {
    padding: 20px 40px;
  }

  .card-header {
    border-bottom: 1px solid #D0CBCB;
    margin-bottom: 20px;

    h3 {
      text-align: center;
      width: 100%;
    }
  }

  .card-footer {
    background: none;
    border-top: 1px solid #D0CBCB;
    margin-top: 20px;
    padding-top: 20px;
  }

  &.actions {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: center;

    .pull-right {
      float: none;
    }

    .btn {
      -webkit-box-shadow: 0 0 30px -10px #4f5f6f;
      -moz-box-shadow: 0 0 30px -10px #4f5f6f;
      box-shadow: 0 0 30px -10px #4f5f6f;
      margin-bottom: 20px;

      &.btn-primary {
        background: #D0CBCB;
        color: #554848;
        font-weight: 700;

        &:hover {
          background-color: #554848;
          color: #ffffff;
        }

        &:focus {
          -webkit-box-shadow: 0 0 30px -10px #4f5f6f;
          -moz-box-shadow: 0 0 30px -10px #4f5f6f;
          box-shadow: 0 0 30px -10px #4f5f6f;
        }
      }
    }
  }
}

@media (max-width: 599.98px) {
  .card-footer {
    .actions.text-right {
      text-align: center !important;

      .btn {
        margin-bottom: 20px;
      }
    }
  }
  .footer{
    font-size: 0.9em;
    padding: 0 10px;
    > .cga{
      > a{
        padding: 0;
        > span{
          &:nth-child(1){
            display:none;
          }
          &:nth-child(2){
            display:block;
          }
        }
      }
    }
  }
}

.form-control {
  border: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
}

.form-group {
  margin-bottom: 2rem;

  &.form-group-thin {
    margin-bottom: 1rem;
  }
}

form[name="registration_form"] {
  .form-group {
    margin-bottom: 1rem;
  }
}

.section-title {
  font-weight: 400;
  margin-bottom: 2rem;

  i {
    margin-left: 0.3em;
  }
}

.sidebar {
  background-color: #554848;

  .brand {
    margin-top: 15px;

    img {
      height: auto;
      width: 190px;
    }
  }

  .sidebar-menu {
    margin-top: 15px;

    li {
      border-bottom: 1px solid #B2A7A7;

      a {
        color: #B2A7A7;
        font-size: 16px;

        i {
          font-size: 1.7em;
          margin-right: 15px;
        }

        &:hover {
          background: transparent;
        }
      }
    }
  }
}

.header {
  background-color: #9B8F8E;
}

.app {
  background-color: #D1CCC9;
}

form[name="registration_form"],
form[name="buy_licence_form"] {
  p.select-licence {
    text-align: center;
  }

  .licence-type-selection {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(center);

    > .licence-type {
      min-width: 220px;
      width: 50%;

      .form-check {
        padding-left: 0;
        text-align: center;

        .form-check-label {
          cursor: pointer;

          > span {
            > span {
              &:nth-of-type(1),
              &:nth-of-type(2) {
                display: none;
              }
            }
          }

          &:before {
            background: no-repeat top left;
            background-size: auto 100%;
            content: '';
            display: block;
            height: 175px;
            margin: auto;
            width: 175px;
          }

          &[data-type="biceps_obligatoire"] {
            &:before {
              background-image: url(../../images/btn_BICEPS_EO_both.png);
            }
          }

          &[data-type="biceps_superieure"] {
            &:before {
              background-image: url(../../images/btn_BICEPS_MS_both.png);
            }
          }
        }

      }
    }

    > .quantity {
      width: 100%;
    }
  }
}

.btn {
  &.btn-small {
    background-color: #E0DBD9;
    border-radius: 2px;
    margin: 0 5px;
    padding: 8px 16px;
  }

  &.renew {
    > i {
      display: none !important;
    }

    > span {
      display: block !important;
    }
  }
}

.header .header-block-collapse .collapse-btn {
  color: #ffffff;
}

.orders-list {
  table {
    thead {
      th:nth-of-type(1) {
        max-width: 100px;
      }

      th:nth-of-type(4) {
        max-width: 250px;
      }
    }

    tbody {
      tr {
        td:nth-of-type(1) {
          max-width: 100px;

          span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        td:nth-of-type(4) {
          max-width: 250px;

          span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        td:nth-of-type(6) {
          span {
            &:nth-of-type(1) {
              display: none;
            }
          }
        }
      }
    }
  }
}

.licences-list {
  table {
    tbody {
      tr {
        td{
          .indented {
            padding-left: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 1439.98px) {
  .licences-list {
    table {
      thead {
        tr {
          th:nth-of-type(3) {
            display: none;
          }
        }
      }

      tbody {
        tr {
          td{
            .indented {
              padding-left: 0;
            }
          }
          td:nth-of-type(3) {
            display: none;
          }

          td:nth-of-type(6) {
            .btn {
              padding: 5px 10px;

              &.info {
                i {
                  display: inline-block !important;
                }

                span {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .orders-list {
    table {
      tbody {
        tr {
          td:nth-of-type(4) {
            display: none;
          }

          td:nth-of-type(6) {
            span {
              &:nth-of-type(1) {
                display: inline-block;
              }

              &:nth-of-type(2) {
                display: none;
              }
            }
          }

          td:nth-of-type(7) {
            .btn {
              padding: 5px 10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1279.98px) {
  .licences-list {
    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          td {
            vertical-align: middle;

            .indented {
              padding-left: 0;
            }
          }

          td:nth-of-type(4) {
            display: none;
          }
        }
      }
    }
  }
  .orders-list {
    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          td {
            vertical-align: middle;
          }

          td:nth-of-type(2) {
            display: none;
          }

          td:nth-of-type(6) {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .licences-list {
    .app .content {
      padding-left: 0;
      padding-right: 0;

      .col-md-12 {
        padding-left: 0;
        padding-right: 0;

        .card {
          border-radius: 0;
          margin-top: 40px;

          .card-block {
            padding: 0 0 20px 0;
            position: relative;

            .card-title-block {
              left: 20px;
              position: absolute;
              top: -40px;
            }
          }
        }
      }
    }

    table {
      tbody {
        tr {

          td:nth-of-type(1) {
            display: none;
          }

          td:nth-of-type(5) {
            text-align: center;

            span {
              span {
                display: none;
              }

              &:before {
                background: #95BC4B;
                border: 2px solid #ffffff;
                border-radius: 50%;
                -webkit-box-shadow: 0 0 3px -1px #4f5f6f;
                -moz-box-shadow: 0 0 3px -1px #4f5f6f;
                box-shadow: 0 0 3px -1px #4f5f6f;
                content: '';
                display: inline-block;
                height: 16px;
                width: 16px;
              }

              &.label-warning {
                &:before {
                  background: #B2A7A7;
                }
              }
            }
          }
        }
      }
    }
  }
  .orders-list {
    .app .content {
      padding-left: 0;
      padding-right: 0;

      .card {
        border-radius: 0;
        margin-top: 40px;

        .card-block {
          padding: 0 0 20px 0;
          position: relative;

          .card-title-block {
            left: 20px;
            position: absolute;
            top: -40px;
          }
        }
      }
    }

    table {
      tbody {
        tr {
          td:nth-of-type(1) {
            text-align: center;

            span {
              span {
                display: none;
              }

              &:before {
                background: #B2A7A7;
                border: 2px solid #ffffff;
                border-radius: 50%;
                -webkit-box-shadow: 0 0 3px -1px #4f5f6f;
                -moz-box-shadow: 0 0 3px -1px #4f5f6f;
                box-shadow: 0 0 3px -1px #4f5f6f;
                content: '';
                display: inline-block;
                height: 16px;
                width: 16px;
              }

              &.danger {
                &:before {
                  background: red;
                }
              }

              &.success {
                &:before {
                  background: #95BC4B;
                }
              }
            }
          }

          td:nth-of-type(5) {
            span {
              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 599.98px) {
  .licences-list {
    table {
      tbody {
        tr {

          td:nth-of-type(6) {
            .btn {
              &.renew {
                i {
                  display: inline-block !important;
                }

                span {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .orders-list {
    table {
      tbody {
        tr {
          td:nth-of-type(3) {
            span {
              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .order-info {
    table {
      tbody {
        tr {
          td {
            &:nth-of-type(1) {
              max-width: 120px;

              span {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }

          &:nth-of-type(4) {
            td {
              span {
                span {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  .profile-row{
    .add-email-col{
      text-align: center;
    }
  }
}

.fa {
  font-family: 'BicepsAdminRegular', sans-serif;

  &.fa-bars {
    &:before {
      content: 'M';
    }
  }

  &.fa-bell-o {
    &:before {
      content: 'A';
    }
  }

  &.fa-dollar {
    &:before {
      content: 'I';
    }
  }

  &.fa-file-text-o {
    &:before {
      content: 'L';
    }
  }

  &.fa-home {
    &:before {
      content: 'H';
    }
  }

  &.fa-key {
    &:before {
      content: 'L';
    }
  }

  &.fa-power-off {
    &:before {
      content: 'D';
    }
  }

  &.fa-print {
    &:before {
      content: 'P';
    }
  }

  &.fa-rotate-left {
    &:before {
      content: 'R';
    }
  }

  &.fa-search {
    &:before {
      content: 'I';
    }
  }

  &.fa-shopping-cart {
    &:before {
      content: 'C';
    }
  }

  &.fa-trash {
    &:before {
      content: 'T';
    }
  }

  &.fa-user {
    &:before {
      content: 'U';
    }
  }

  &.fa-usd {
    &:before {
      content: 'O';
    }
  }
}
